import React, {
  useState,
  useRef,
  useId,
  ElementType,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useReducer,
} from 'react';

import { Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useCustomTableContext } from '../globalContexts/CustomTableContext';

export type CustomTableForwardRefProps = {
  expand: (index: number) => void;
  close: (index: number) => void;
  isShowDetailInfo: (index: number) => boolean;
};

export type CustomTableProps = {
  stickyHeader?: boolean;
  borderNone?: boolean;
  lastBorderNone?: boolean;
  size?: 'medium' | 'small';
  component?: ElementType<any>;
  sx?: SxProps<Theme>;
  tableHead?: (string | number | JSX.Element)[];
  tableBody: {
    key: string;
    onMouseDown?: (mouseDownInfo: {
      key: string;
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
    }) => void;
    content: (string | number | JSX.Element)[];
    hasDetailInfo?: boolean;
    sx?: SxProps<Theme>;
  }[];
  tableRef?: React.RefObject<HTMLDivElement>;
  cellWidth?: {
    [cellIndex: string]: number;
  };
  hasLastColumnButtonGroup?: boolean;
};

const CustomTable = forwardRef<CustomTableForwardRefProps, CustomTableProps>(
  (
    {
      stickyHeader = false,
      borderNone = false,
      lastBorderNone = false,
      size = 'medium',
      component = Box,
      sx = { width: '100%' },
      tableHead,
      tableBody,
      tableRef: tableContainerRef,
      cellWidth,
      hasLastColumnButtonGroup = false,
    },
    ref,
  ): JSX.Element => {
    const { isShowDetailInfo, setIsShowDetailInfo } = useCustomTableContext();

    const [showDetailInfo, updateShowDetailInfo] = useReducer(
      (state: boolean[], { index, isShow }: { index: number; isShow: boolean }) => {
        const stateCopy = [...state];
        stateCopy[index] = isShow;
        return stateCopy;
      },
      isShowDetailInfo?.current
        ? [...isShowDetailInfo.current]
        : new Array(tableBody.length).fill(false),
    );
    const showDetailInfoRef = useRef(showDetailInfo);
    useEffect(() => {
      showDetailInfoRef.current = showDetailInfo;
      setIsShowDetailInfo(showDetailInfoRef);
    }, [showDetailInfo]);

    useImperativeHandle(ref, () => ({
      expand: (index) => updateShowDetailInfo({ index, isShow: true }),
      close: (index) => updateShowDetailInfo({ index, isShow: false }),
      isShowDetailInfo: (index) => showDetailInfoRef.current[index],
    }));

    const headId = useId();
    const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const elementRef = tableContainerRef ? { ref: tableContainerRef } : {};

    const tableId = useId();
    const tableRef = useRef<HTMLElement | null>(null);
    const [showVerticalScrollbar, setShowVerticalScrollbar] = useState(false);

    const updateShowVerticalScrollbar = () => {
      if (!hasLastColumnButtonGroup || isScreenDownMd) {
        return;
      }

      const tableElement = tableRef.current
        ? tableRef.current
        : document.getElementById(tableId);
      if (!tableRef.current) {
        tableRef.current = tableElement;
      }

      const tableContainerWidth = tableElement?.parentElement?.offsetWidth ?? 0;
      const tableWidth = tableElement?.offsetWidth ?? 0;

      if (tableWidth - tableContainerWidth >= 16) {
        setShowVerticalScrollbar(true);
      } else {
        setShowVerticalScrollbar(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', updateShowVerticalScrollbar);

      return () => {
        window.removeEventListener('resize', updateShowVerticalScrollbar);
      };
    }, [hasLastColumnButtonGroup, isScreenDownMd]);

    useEffect(() => {
      updateShowVerticalScrollbar();
    }, [elementRef?.ref?.current, hasLastColumnButtonGroup, isScreenDownMd, tableBody]);

    return (
      <TableContainer
        component={component}
        sx={{
          ...sx,
          ...(!showVerticalScrollbar && !isScreenDownMd ? { overflowX: 'hidden' } : null),
        }}
        {...elementRef}
      >
        <Table size={size} stickyHeader={stickyHeader} id={tableId}>
          {isScreenDownMd && (
            <TableBody>
              {tableBody.reduceRight<JSX.Element>(
                (prev, { key, onMouseDown, content, hasDetailInfo, sx }, index) => (
                  <React.Fragment key={key}>
                    <TableRow
                      onMouseDown={(event) => onMouseDown && onMouseDown({ key, event })}
                    >
                      <TableCell
                        key={`${key}-${index}-1`}
                        {...(sx
                          ? { sx: { ...sx, ...(borderNone ? { border: 'none' } : null) } }
                          : borderNone
                          ? { sx: { border: 'none' } }
                          : null)}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={1}
                            sx={{ width: '100%' }}
                          >
                            {(hasDetailInfo || hasLastColumnButtonGroup
                              ? content.slice(0, -1)
                              : content
                            ).map((cellContent, index) => (
                              <Stack
                                key={`${key}-${index}-2`}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={0.5}
                              >
                                {tableHead && tableHead[index] && (
                                  <Box
                                    fontWeight="bold"
                                    sx={{
                                      minWidth: hasLastColumnButtonGroup ? '35%' : '30%',
                                      //wordBreak: 'break-all',
                                    }}
                                  >
                                    {tableHead[index]}
                                  </Box>
                                )}
                                {(tableHead && tableHead[index] && (
                                  <Box
                                    sx={{
                                      minWidth: hasLastColumnButtonGroup ? '65%' : '70%',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    {cellContent}
                                  </Box>
                                )) || (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    {cellContent}
                                  </Box>
                                )}
                              </Stack>
                            ))}
                          </Stack>
                          {hasLastColumnButtonGroup && !hasDetailInfo && (
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={0}
                              sx={{ maxWidth: '36px' }}
                            >
                              {content.slice(-1).map((cellContent, index) => (
                                <Box key={`${key}-${index}-3`}>{cellContent}</Box>
                              ))}
                            </Stack>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                    {hasDetailInfo && showDetailInfo[index] && (
                      <TableRow>
                        <TableCell>{content[content.length - 1]}</TableCell>
                      </TableRow>
                    )}
                    {prev}
                  </React.Fragment>
                ),
                <></>,
              )}
            </TableBody>
          )}
          {!isScreenDownMd && (
            <TableHead
              {...(!tableHead || isScreenDownMd ? { sx: { display: 'none' } } : null)}
            >
              <TableRow>
                {tableHead?.map((cellContent, index) => (
                  <TableCell
                    key={`${headId}-${index}`}
                    sx={{
                      // fontWeight: '400',
                      // fontSize: '10px',
                      // lineHeight: '18px',
                      fontWeight: 'bold',
                      color: '#073015',
                      ...(borderNone ? { border: 'none' } : null),
                    }}
                  >
                    {cellContent}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          {!isScreenDownMd && (
            <TableBody {...(isScreenDownMd ? { sx: { display: 'none' } } : null)}>
              {tableBody.map(
                ({ key, onMouseDown, content, hasDetailInfo, sx }, bodyIndex) => (
                  <React.Fragment key={key}>
                    <TableRow
                      hover
                      onMouseDown={(event) => onMouseDown && onMouseDown({ key, event })}
                    >
                      {(hasDetailInfo ? content.slice(0, -1) : content).map(
                        (cellContent, index) => {
                          const width = Object.assign(
                            {},
                            cellWidth && cellWidth[`${index}`]
                              ? { width: cellWidth[`${index}`] }
                              : null,
                          );

                          const lastRowBorderNone = !!(
                            lastBorderNone && bodyIndex === tableBody.length - 1
                          );

                          return (
                            <TableCell
                              key={`${key}-${index}`}
                              {...width}
                              {...(sx
                                ? {
                                    sx: {
                                      ...sx,
                                      ...(borderNone ||
                                      lastRowBorderNone ||
                                      (hasDetailInfo && showDetailInfo[bodyIndex])
                                        ? { border: 'none' }
                                        : null),
                                    },
                                  }
                                : borderNone ||
                                  lastRowBorderNone ||
                                  (hasDetailInfo && showDetailInfo[bodyIndex])
                                ? { sx: { border: 'none' } }
                                : null)}
                            >
                              {cellContent}
                            </TableCell>
                          );
                        },
                      )}
                    </TableRow>
                    {hasDetailInfo && showDetailInfo[bodyIndex] && (
                      <TableRow>
                        <TableCell
                          colSpan={tableHead?.length ?? 1}
                          sx={{
                            ...(lastBorderNone && bodyIndex === tableBody.length - 1
                              ? { borderBottom: 'none' }
                              : null),
                          }}
                        >
                          {content[content.length - 1]}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    );
  },
);
CustomTable.displayName = 'CustomTable';

export default CustomTable;
