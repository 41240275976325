import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';

export type Language = 'US' | 'TW';

interface LanguageContextType {
  language: Language;
  changeLanguage: (language: Language) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [language, setLanguage] = useState(() => {
    const language = localStorage.getItem('language');
    if (language) return language;
    if (navigator.language)
      switch (navigator.language.toLowerCase()) {
        case 'zh-cn':
        case 'zh-hk':
          return 'TW';
        case 'zh-tw':
          return 'US';
        default:
          return 'US';
      }
    return 'US';
  });

  const prevLanguage = useRef(language);

  const [value, setValue] = useState<LanguageContextType>(() => ({
    language: language as Language, // 將 language 轉換為 Language 類型
    changeLanguage(language: Language) {
      localStorage.setItem('language', language);
      setLanguage(language);
    },
  }));

  useEffect(() => {
    if (prevLanguage.current !== language) {
      prevLanguage.current = language;
      setValue((value) => ({ ...value, language: language as Language }));
    }
  }, [language]);

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

const noScript = { US: 'No Script', TW: '文本缺失' };

export const useScriptConverter = (): ((script: Record<Language, string>) => string) => {
  const { language } = useLanguage();

  const converter = useCallback(
    (script: Record<Language, string>) => {
      return typeof script === 'string'
        ? script
        : script[language] ||
            (language === 'US' && script.US
              ? script.US
              : language === 'TW' && script.TW
              ? script.TW
              : script.US);
    },
    [language],
  );

  return converter;
};

export const createScripts = (converter: (script: Record<Language, string>) => string) =>
  new Proxy(
    {} as Record<string, string>, // 使用字串作為索引類型
    {
      get: (target, prop) =>
        prop in target ? target[prop as string] : converter(noScript),
      set: (target, prop, value) => {
        target[prop as string] = converter(value ?? noScript);
        return true;
      },
    },
  );

const useMakeScript = (rawScripts: Record<string, Record<Language, string>> = {}) => {
  const converter = useScriptConverter();

  const languageScripts = useMemo(() => {
    const scripts = createScripts(converter);
    for (const key in rawScripts) {
      scripts[key] = rawScripts[key] as unknown as any;
    }

    return scripts;
  }, [converter]);

  return languageScripts;
};

export const useScripts = () => {
  const languageDictionary = useMemo(
    () => ({
      //landing
      Today: { US: 'TODAY', TW: '首頁' },
      Learn: { US: 'LEARN', TW: '學習' },
      Teach: { US: 'TEACH', TW: '教學' },
      Resources: { US: 'RESOURCES', TW: '資源' },
      source: { US: 'Source', TW: '來源' },
      School: { US: 'SCHOOL', TW: '學校' },
      Schedule: { US: 'SCHEDULE', TW: '行程' },
      Classes: { US: 'CLASSES', TW: '課程' },
      Leads: { US: 'LEADS', TW: '潛在客戶' },
      Daily: { US: 'DAILY', TW: '日計劃' },
      Account: { US: 'ACCOUNT', TW: '帳戶' },
      Logout: { US: 'LOGOUT', TW: '登出' },
      Chinese: { US: 'CHINESE', TW: '中文' },
      English: { US: 'ENGLISH', TW: '英文' },
      Language: { US: 'English', TW: '中文' },
      Fun: { US: 'FUN', TW: '樂趣' },
      Homework: { US: 'Homework', TW: '作業' },
      More: { US: 'MORE', TW: '更多' },

      todayPage: { US: 'Today Page', TW: '首頁' },

      reset: { US: 'Reset', TW: '重置' },
      duplicate: { US: 'Duplicate', TW: '複製' },
      showing: { US: 'Showing', TW: '顯示' },
      hiding: { US: 'Hiding', TW: '隱藏' },
      edit: { US: 'Edit', TW: '編輯' },
      trash: { US: 'Trash', TW: '垃圾桶' },
      resources: { US: 'Resources', TW: '資源' },
      leads: { US: 'Leads', TW: '潛在客戶' },
      daily: { US: 'Daily', TW: '日計劃' },
      teachDaily: { US: 'Teach Daily', TW: '每日教學' },
      MUC: { US: 'MUC', TW: '補課' },
      MakeUpClass: { US: 'Make-Up Class', TW: '補課' },
      No: { US: 'No.', TW: '編號' },

      language: { US: 'Language', TW: '語言' },

      mode: { US: 'Mode', TW: '模式' },
      auto: { US: 'Auto', TW: '自動' },
      darkMode: { US: ' Dark Mode', TW: '深色模式' },
      lightMode: { US: ' Light Mode', TW: '淺色模式' },

      previousDays: { US: 'Previous days', TW: '前幾天' },
      addOneMoreDay: { US: 'Add One More Day', TW: '再增加一天' },

      addEvent: { US: 'Add Event', TW: '新增活動' },
      addEventAttended: { US: 'Add Event Attended', TW: '加入參加的活動' },

      pleaseChooseAEvent: { US: 'Please choose a Event.', TW: '請選擇一個活動' },

      goals: { US: 'Goals', TW: '目標' },
      teachingOrPreparing: { US: 'Teaching or Preparing ', TW: '教學或備課 ' },

      ['User not exists']: { US: 'User not exists', TW: '使用者不存在' },
      ['Incorrect password']: { US: 'Incorrect password', TW: '密碼錯誤' },

      duplicateLevel: { US: 'Duplicate Level', TW: '複製 Level' },
      duplicateLesson: { US: 'Duplicate Lesson', TW: '複製 Lesson' },
      duplicateBook: { US: 'Duplicate Book', TW: '複製 Book' },
      duplicateStyle: { US: 'Duplicate Style', TW: '複製 Style' },
      duplicatePart: { US: 'Duplicate Part', TW: '複製 Part' },

      //sidebar
      ['Background']: { US: 'Background', TW: '背景' },
      ['My Image']: { US: 'My Image', TW: '我的圖片' },
      ['My Audio']: { US: 'My Audio', TW: '我的音檔' },
      ['My Video']: { US: 'My Video', TW: '我的影片' },
      ['Notes']: { US: 'Notes', TW: '筆記' },
      ['Text']: { US: 'Text', TW: '文字' },
      ['Elements']: { US: 'Elements', TW: '元件' },
      ['Answer Options']: { US: 'Answer Options', TW: '答案選項' },
      ['Mic']: { US: 'Mic', TW: '麥克風' },
      ['Layers']: { US: 'Layers', TW: '層級' },
      ['Saved Style']: { US: 'Saved Style', TW: '儲存樣式' },

      ['Pexel']: { US: 'Pexels', TW: 'Pexels' },
      ['Pixabay']: { US: 'Pixabay', TW: 'Pixabay' },
      ['Unsplash']: { US: 'Unsplash', TW: 'Unsplash' },
      ['Giphy']: { US: 'Giphy', TW: 'Giphy' },

      ['Search']: { US: 'Search', TW: '搜尋' },

      //toolbar
      ['Pointer']: { US: 'Pointer', TW: '指針' },
      ['Brushes']: { US: 'Brushes', TW: '筆刷' },
      ['Spotlight']: { US: 'Spotlight', TW: '聚光燈' },
      ['Stickers']: { US: 'Stickers', TW: '貼紙' },
      ['PK']: { US: 'PK', TW: 'PK' },
      ['Spinners']: { US: 'Spinners', TW: '轉盤' },
      ['Eraser']: { US: 'Eraser', TW: '橡皮擦' },
      ['Undo']: { US: 'Undo', TW: '上一步' },
      ['Redo']: { US: 'Redo', TW: '下一步' },
      ['Trash']: { US: 'Trash', TW: '垃圾桶' },

      ['Please get the contract of MUSEN first.']: {
        US: 'Please get the contract of MUSEN first.',
        TW: '請先獲取木森的合約',
      },

      ['User is deactivated']: {
        US: 'User is deactivated',
        TW: '使用者已停用',
      },

      ['InputError: Password is required']: {
        US: 'Password is required',
        TW: '請輸入Password',
      },

      ['InputError: Username is required']: {
        US: 'Username is required',
        TW: '請輸入Username',
      },

      unknownRole: {
        US: 'Your character is unrecognizable.',
        TW: '您的角色無法辨識',
      },

      brush: { US: 'Brush', TW: '刷子' },
      hightlighter: { US: 'Hightlighter', TW: '螢光筆' },
      colorPicker: { US: 'Color picker', TW: '調色盤' },
      size: { US: 'Size', TW: '大小' },
      close: { US: 'Close', TW: '關閉' },

      color: { US: 'Color', TW: '顏色' },
      colors: { US: 'Colors', TW: '顏色' },
      customColors: { US: 'Custom Colors', TW: '自定義顏色' },
      defaultColor: { US: 'Default color', TW: '預設顏色' },
      defaultColors: { US: 'Default colors', TW: '預設顏色' },
      gradients: { US: 'Gradients', TW: '漸變' },
      gradientColors: { US: 'Gradient colors', TW: '漸層色' },
      style: { US: 'Style', TW: '樣式' },
      goBack: { US: 'Go back', TW: '回去' },
      text: { US: 'Text', TW: '文字' },
      texts: { US: 'Texts', TW: '文字' },
      image: { US: 'Image', TW: '圖片' },
      images: { US: 'Images', TW: '圖片' },
      other: { US: 'Other', TW: '其他' },
      book: { US: 'Book', TW: '書' },
      books: { US: 'Books', TW: '書' },
      contracts: { US: 'Contracts', TW: '合約' },
      duration: { US: 'Duration', TW: '期間' },
      teach: { US: 'Teach', TW: '教學' },
      school: { US: 'School', TW: '學校' },
      Unassigned: { US: 'Unassigned', TW: '未指派' },

      dailyPlan: { US: 'daily plan', TW: '每日計畫' },

      teachingMode: { US: 'Teaching Mode', TW: '教學模式' },
      preparingMode: { US: 'Preparing Mode', TW: '備課模式' },
      homeworkMode: { US: 'Homework Mode', TW: '作業模式' },

      preparing: { US: 'Preparing', TW: '備課' },
      teaching: { US: 'Teaching', TW: '教學' },

      addColor: { US: 'Add color', TW: '新增顏色' },
      linearGradient: { US: 'Linear gradient', TW: '線性漸變' },
      circularGradientAt: { US: 'Circular gradient at', TW: '圓型漸變' },

      hideResourceShowResource: {
        US: 'Hide Resource / Show Resource',
        TW: '隱藏 Resource / 顯示 Resource',
      },

      hideLessonShowLesson: {
        US: 'Hide Lesson / Show Lesson',
        TW: '隱藏 Lesson / 顯示 Lesson',
      },

      hideBookShowBook: {
        US: 'Hide Book / Show Book',
        TW: '隱藏 Book / 顯示 Book',
      },

      hidePartShowPart: {
        US: 'Hide Part / Show Part',
        TW: '隱藏 Part / 顯示 Part',
      },

      hideStyleShowStyle: {
        US: 'Hide Style / Show Style',
        TW: '隱藏 Style / 顯示 Style',
      },

      hideToStudent: {
        US: 'Hide to student / Show to student',
        TW: '對學生隱藏 / 對學生顯示',
      },

      restoreLevel: { US: 'Restore Level', TW: '復原 Level' },
      restoreLevels: { US: 'Restore Levels', TW: '復原 Levels' },
      restoreLesson: { US: 'Restore Lesson', TW: '復原 Lesson' },
      restoreLessons: { US: 'Restore Lessons', TW: '復原 Lessons' },
      restoreBook: { US: 'Restore Book', TW: '復原 Book' },
      restoreBooks: { US: 'Restore Books', TW: '復原 Books' },
      restorePart: { US: 'Restore Part', TW: '復原 Part' },
      restoreParts: { US: 'Restore Parts', TW: '復原 Parts' },
      restoreStyle: { US: 'Restore Style', TW: '復原 Style' },
      restoreStyles: { US: 'Restore Styles', TW: '復原 Styles' },
      restoreResource: { US: 'Restore Resource', TW: '復原 Resource' },

      addLevel: { US: 'Add Level', TW: '新增 Level' },
      addLesson: { US: 'Add Lesson', TW: '新增 Lesson' },
      addBook: { US: 'Add Book', TW: '新增 Book' },
      addPart: { US: 'Add Part', TW: '新增 Part' },
      addStyle: { US: 'Add Style', TW: '新增 Style' },

      editLevel: { US: 'Edit Level', TW: '編輯 Level' },
      editLesson: { US: 'Edit Lesson', TW: '編輯 Lesson' },
      editBook: { US: 'Edit Book', TW: '編輯 Book' },
      editPart: { US: 'Edit Part', TW: '編輯 Part' },
      editStyle: { US: 'Edit Style', TW: '編輯 Style' },

      addSource: { US: 'Add Source ', TW: '新增 Source' },
      editSource: { US: 'Edit Source', TW: '編輯 Source' },
      addLinks: { US: 'Add links', TW: '新增連結' },
      editLink: { US: 'Edit link', TW: '編輯連結' },

      editSchools: { US: 'Edit Schools', TW: '編輯學校' },

      schools: { US: 'Schools', TW: '學校' },
      mySchools: { US: 'My Schools', TW: '我的學校' },

      addResource: { US: 'Add Resource', TW: '新增 Resource' },
      editResources: { US: 'Edit Resources', TW: '編輯 Resources' },
      editResource: { US: 'Edit Resource', TW: '編輯 Resource' },

      editAccess: { US: 'Edit Access', TW: '編輯存取權限' },

      whoCanAccess: { US: 'Who can access?', TW: '誰可以訪問?' },

      access: { US: 'Access', TW: '使用權' },

      teacherCodes: { US: 'Teacher Codes', TW: '老師授權碼' },
      codes: { US: 'Codes', TW: '授權碼' },

      classroomDaily: { US: 'Classroom Daily', TW: '教室日計畫' },

      copy: { US: 'Copy', TW: '複製' },
      share: { US: 'Share', TW: '分享' },
      copyDaily: { US: 'Copy Daily', TW: '複製日計畫' },
      copyTo: { US: 'Copy To', TW: '複製到' },

      unrestricted: { US: 'Unrestricted', TW: '無限制' },

      addContract: { US: 'Add Contract', TW: '新增合約' },
      editContract: { US: 'Edit Contract', TW: '編輯合約' },
      deleteContract: { US: 'Delete Contract', TW: '刪除合約' },

      resetPassword: { US: 'Reset Password', TW: '重置密碼' },
      newPassword: { US: 'New Password', TW: '新密碼' },

      numberOfQuestion: { US: 'Number of question', TW: '問題數量' },

      all: { US: 'All', TW: '全部' },

      newCall: { US: 'New Call', TW: '新通話紀錄' },

      callBy: { US: 'Call By', TW: '誰打電話' },
      callDate: { US: 'Call Date', TW: '打電話日期' },
      by: { US: 'By', TW: '派發人' },

      addBookTo: { US: 'Add a Book to', TW: '新增 Book 至 ' },
      editBookFrom: { US: 'Edit a Book from', TW: '編輯 Book 來自 ' },

      levelTrash: { US: 'Level Trash', TW: 'Level 垃圾桶' },
      lessonTrash: { US: 'Lesson Trash', TW: 'Lesson 垃圾桶' },
      bookTrash: { US: 'Book Trash', TW: 'Book 垃圾桶' },
      partTrash: { US: 'Part Trash', TW: 'Part 垃圾桶' },
      styleTrash: { US: 'Style Trash', TW: 'Style 垃圾桶' },
      resourceTrash: { US: 'Resource Trash ', TW: 'Resource 垃圾桶' },
      mediaTrash: { US: 'Media Trash ', TW: 'Media 垃圾桶' },

      deleteWarning: {
        US: ` and everything inside it will be moved to the
          trash. You can recover it from the trash if you
          change your mind. `,
        TW: ` 裡面的資料會一併丟進垃圾桶.您可以從垃圾桶中復原`,
      },

      ok: { US: 'OK', TW: '確認' },
      done: { US: 'Done', TW: '完成' },
      save: { US: 'Save', TW: '儲存' },
      restore: { US: 'Restore', TW: '復原' },
      submit: { US: 'Submit', TW: '提交' },
      cancel: { US: 'Cancel', TW: '取消' },
      warning: { US: 'Warning!', TW: '警告!' },
      delete: { US: 'Delete', TW: '刪除' },
      product: { US: 'Product', TW: '產品' },
      products: { US: 'Products', TW: '產品' },
      level: { US: 'Level', TW: '等級' },
      name: { US: 'Name', TW: '名字' },
      email: { US: 'Email', TW: '信箱' },
      date: { US: 'Date', TW: '日期' },
      address: { US: 'Address', TW: '地址' },
      username: { US: 'Username', TW: '使用者名稱' },
      password: { US: 'Password', TW: '密碼' },
      confirmPassword: { US: 'ConfirmPassword', TW: '確認密碼' },
      audio: { US: 'Audio', TW: '音檔' },
      audios: { US: 'Audios', TW: '音檔' },
      audioName: { US: 'Audio name', TW: '音檔名字' },
      score: { US: 'Score', TW: '分數' },
      test: { US: 'Test', TW: '測驗' },
      back: { US: 'Back', TW: '上一步' },
      next: { US: 'Next', TW: '下一步' },
      startDate: { US: 'Start Date', TW: '開始日期' },
      endDate: { US: 'End Date', TW: '結束日期' },
      startTime: { US: 'Start Time', TW: '開始時間' },
      endTime: { US: 'End Time', TW: '結束時間' },
      applications: { US: 'Applications', TW: '應用程式' },
      phone: { US: 'Phone', TW: '電話' },
      parentPhone: { US: 'Parent Phone', TW: '家長電話' },
      parentName: { US: 'Parent Name', TW: '家長姓名' },
      studentPhone: { US: 'Student Phone', TW: '學生電話' },
      englishLevel: { US: 'English Level', TW: '英文級別' },
      teacher: { US: 'Teacher', TW: '老師' },
      teachers: { US: 'Teachers', TW: '老師' },
      tip: { US: 'Tip', TW: '提示' },

      ['Admin Teacher']: { US: 'Admin Teacher', TW: '行政老師' },
      super: { US: 'Super', TW: '超級帳號' },
      student: { US: 'Student', TW: '學生' },
      students: { US: 'Students', TW: '學生' },
      schoolGrade: { US: 'School Grade', TW: '學校年級' },
      englishName: { US: 'English Name', TW: '英文名字' },
      studentName: { US: 'Student Name', TW: '學生名字' },
      classroom: { US: 'Classroom', TW: '教室' },
      classrooms: { US: 'Classrooms', TW: '教室' },
      customer: { US: 'Customer', TW: '顧客' },
      overview: { US: 'Overview', TW: '概述' },
      more: { US: ' More', TW: '更多' },
      collapse: { US: 'Collapse', TW: '折疊' },
      change: { US: 'Change', TW: '變更' },
      call: { US: 'Call', TW: '通話' },
      calls: { US: 'Calls', TW: '通話' },
      callNotes: { US: 'Call Notes', TW: '通話筆記' },
      event: { US: 'Event', TW: '活動' },
      filter: { US: 'Filter', TW: '篩選' },
      country: { US: 'Country', TW: '國家' },
      city: { US: 'City', TW: '城市' },
      district: { US: 'District', TW: '區' },
      details: { US: 'Details', TW: '細節' },
      prepare: { US: 'Prepare', TW: '準備' },
      quiz: { US: 'Quiz', TW: '測驗' },
      notes: { US: 'Notes', TW: '筆記' },
      progress: { US: 'Progress', TW: '進度' },
      roster: { US: 'Roster', TW: '名冊' },
      questions: { US: 'Questions', TW: '問題' },
      answer: { US: 'Answer', TW: '答案' },
      answers: { US: 'Answers', TW: '答案' },

      studentRoster: { US: 'Student Roster', TW: '學生名冊' },

      nextWeek: { US: 'Next Week', TW: '下一週' },
      lastWeek: { US: 'Last Week', TW: '上一週' },

      yesterday: { US: 'Yesterday', TW: '昨天' },
      today: { US: 'Today', TW: '今天' },
      tomorrow: { US: 'Tomorrow', TW: '明天' },

      readyToSchedule: { US: 'Ready To Schedule', TW: '準備安排' },
      updateSchedule: { US: 'Update Schedule', TW: '更新時間表' },

      finish: { US: 'Finish', TW: '完成' },
      finalScore: { US: 'Final Score', TW: '總分' },

      loadingClassroomData: {
        US: 'Loading classroom Data...',
        TW: '正在載入教室資料...',
      },
      studentHomeworkNotDone: { US: 'Student homework not done', TW: '學生作業沒做完' },

      checkHomework: { US: 'Check Homework', TW: '檢查作業' },
      checkClassGrade: { US: 'Check Class Grade', TW: '檢查班級成績' },

      new: { US: 'New', TW: '新增' },
      add: { US: 'Add', TW: '新增' },
      status: { US: 'Status', TW: '狀態' },
      activated: { US: 'Activated', TW: '啟用' },
      ['Started']: { US: 'Started', TW: '開始' },
      ['Ended']: { US: 'Ended', TW: '結束' },
      ['About to end']: { US: 'About to end', TW: '即將結束' },
      ['About to start']: { US: 'About to start', TW: '即將開始' },
      deactivated: { US: 'Deactivated', TW: '已停用' },

      //code(授權碼)
      ['']: { US: '', TW: '' },
      ['Code']: { US: 'Code', TW: '授權碼' },
      ['Type']: { US: 'Type', TW: '類型' },
      ['Status']: { US: 'Status', TW: '狀態' },
      ['Expiry Date-time']: { US: 'Expiry Date-time', TW: '過期日期' },
      ['Products']: { US: 'Products', TW: '產品' },
      ['Actions']: { US: 'Actions', TW: '動作' },

      newLeads: { US: 'New Leads', TW: '新潛在客戶' },
      totalLeads: { US: 'Total Leads', TW: '全部潛在客戶' },
      customersLost: { US: 'Customers Lost', TW: '客戶流失' },
      dateCustomersLost: { US: 'Date Lost', TW: '客戶流失日期' },
      dateBecameCustomer: { US: 'Date Became Customer', TW: '成為客戶日期' },
      totalCustomers: { US: 'Total Customers', TW: '全部顧客' },
      averageCallsToClose: { US: 'Average calls to close', TW: '平均成單電話數' },

      classTime: { US: 'Class Time', TW: '教室時間' },
      classroomName: { US: 'Classroom Name', TW: '教室名稱' },

      thisMonth: { US: 'This Month', TW: '這個月' },
      averageEventsToClose: { US: 'Average events to close', TW: '平均成單活動數' },
      events: { US: 'Events', TW: '活動' },

      eventName: { US: 'Event Name', TW: '活動名字' },
      eventNotes: { US: 'Event Notes', TW: '活動筆記' },

      sorry: { US: 'Sorry!', TW: '抱歉!' },

      goEnterScores: { US: 'Go enter scores', TW: '輸入分數' },

      youMustEnterTheTestScoresFirst: {
        US: 'You must enter the test scores first',
        TW: '您必須先輸入考試成績',
      },

      currentLevel: { US: 'Current Level', TW: '當前等級' },

      upgradeToLevel: { US: 'Upgrade to level', TW: '升級到等級' },

      beforeYouCanUpgrade: { US: 'before you can upgrade', TW: '在升級之前' },

      teachDailyWasDone: { US: 'Teach Daily was done', TW: '每日教學已完成' },
      teachDailyNotDoneYet: { US: 'Teach Daily not done yet', TW: '每日教學尚未完成' },

      schoolDetail: { US: 'School detail', TW: '學校資訊' },
      editClassroom: { US: 'Edit Classroom', TW: '編輯教室' },
      editSchool: { US: 'Edit School', TW: '編輯學校' },
      editClassroomTime: { US: 'Edit Classroom Time', TW: '編輯教室時間' },
      editClassroomName: { US: 'Edit Classroom Name', TW: '編輯教室名字' },

      addDailyPlan: { US: 'Add Daily Plan', TW: '新增每日計畫' },
      classPlan: { US: 'Class Plan', TW: '課程計劃' },
      activities: { US: 'Activities', TW: '活動' },
      checkTeachDaily: { US: 'Check Teach Daily', TW: '檢查每日教學' },
      selectClassroom: { US: 'Select Classroom', TW: '選擇教室' },
      classGrade: { US: 'Class Grade', TW: '班級成績' },
      checkGrades: { US: 'Check Grades', TW: '檢查成績' },
      studentGrade: { US: 'Student Grade', TW: '學生成績' },
      homework: { US: 'Homework', TW: '作業' },
      skillRating: { US: 'Skill Rating', TW: '技能等級' },

      timeSpent: { US: 'Time Spent', TW: '花費時間' },

      attendance: { US: 'ATTENDANCE', TW: '出席人數' },

      activity1: { US: 'Activity 1', TW: '活動 1' },
      activity2: { US: 'Activity 2', TW: '活動 2' },
      activity3: { US: 'Activity 3', TW: '活動 3' },

      newLead: { US: 'New Lead', TW: '新潛在客戶' },
      addLead: { US: 'Add Lead', TW: '新增潛在客戶' },
      editLead: { US: 'Edit Lead', TW: '編輯潛在客戶' },

      addClassroom: { US: 'Add Classroom', TW: '新增教室' },

      notStartedYet: { US: 'Not started yet', TW: '尚未開始' },
      overallWeakSkills: { US: ' Overall Weak Skills', TW: '整體較弱技能' },

      requiredForLevelData: { US: 'Required for level data', TW: '需要level資料' },

      teachingNotes: { US: ' Teaching Notes', TW: '教學筆記' },
      weakSkills: { US: 'Weak Skills', TW: '較弱技能' },

      homeworkDetail: { US: 'Homework Detail', TW: '作業詳情' },
      homeworkNotDone: { US: 'Homework Not Done', TW: '作業未完成' },
      numberOfStudentsHomeworkNotDone: {
        US: 'Number of Students Homework Not Done',
        TW: '未完成作業的學生人數',
      },
      classSchedule: { US: 'Class Schedule', TW: '課程表' },
      editClassSchedule: { US: 'Edit Class Schedule', TW: '編輯課程表' },

      logout: { US: 'Logout', TW: '登出' },
      teachingGoals: { US: 'Teaching Goals', TW: '教學目標' },
      tickIfDone: { US: 'Tick if done', TW: '如果完成請勾選' },
      print: { US: 'Print', TW: '列印' },

      class: { US: 'Class', TW: '班級' },
      classRecord: { US: 'Class Record', TW: '班級紀錄' },
      myAccount: { US: 'My Account', TW: '我的帳戶' },
      role: { US: 'Role', TW: '身份' },

      createStudent: { US: 'Create Student', TW: '創建學生' },
      createStudents: { US: 'Create Students', TW: '創建學生們' },
      addStudents: { US: 'Add Students', TW: '新增學生' },
      addAChild: { US: 'Add a child', TW: '新增孩子' },
      childsEnglishName: { US: "Child's English Name", TW: '孩子的英文名字' },
      childsChineseName: { US: "Child's Chinese Name", TW: '孩子的中文名字' },
      childsAge: { US: "Child's Age", TW: '孩子的年齡' },
      childsSchool: { US: "Child's School", TW: '孩子的學校' },
      childsNames: { US: "Child's Names", TW: '孩子的名字' },

      chineseName: { US: 'Chinese Name', TW: '中文名字' },
      classMissed: { US: 'Class Missed', TW: '缺課' },

      classesPerWeek: { US: 'Classes per week', TW: '每週課程' },
      classes: { US: 'Classes', TW: '課程' },
      classPlanNeedsAtLeast30Characters: {
        US: 'ClassPlan needs at least 30 characters.',
        TW: 'ClassPlan 需要至少 30 個字元',
      },

      ['Monday']: { US: 'Monday', TW: '星期一' },
      ['Tuesday']: { US: 'Tuesday', TW: '星期二' },
      ['Wednesday']: { US: 'Wednesday', TW: '星期三' },
      ['Thursday']: { US: 'Thursday', TW: '星期四' },
      ['Friday']: { US: 'Friday', TW: '星期五' },
      ['Saturday']: { US: 'Saturday', TW: '星期六' },
      ['Sunday']: { US: 'Sunday', TW: '星期日' },

      ['No class on Monday']: { US: 'No class on Monday.', TW: '星期一無課程' },
      ['No class on Tuesday']: { US: 'No class on Tuesday.', TW: '星期二無課程' },
      ['No class on Wednesday']: { US: 'No class on Wednesday.', TW: '星期三無課程' },
      ['No class on Thursday']: { US: 'No class on Thursday.', TW: '星期四無課程' },
      ['No class on Friday']: { US: 'No class on Friday.', TW: '星期五無課程' },
      ['No class on Saturday']: { US: 'No class on Saturday.', TW: '星期六無課程' },
      ['No class on Sunday']: { US: 'No class on Sunday.', TW: '星期日無課程' },

      startHour: { US: 'Start Hour', TW: '開始時間(時)' },
      startMinute: { US: 'Start Minute', TW: '開始時間(分)' },
      endHour: { US: 'End Hour', TW: '結束時間(時)' },
      endMinute: { US: 'End Minute', TW: '結束時間(分)' },

      classPeriods: { US: 'Class Period', TW: '課時' },

      noStudent: { US: 'No Student', TW: '沒有學生' },
      selectHomework: { US: 'Select homework', TW: '選擇作業' },
      questionSkills: { US: 'Question skills', TW: '提問技巧' },

      seeStudentRecord: { US: 'See Student Record', TW: '查看學生記錄' },

      assignWhichPart: { US: 'Assign which part?', TW: '分配哪個部分?' },

      courseStartDate: { US: 'Course start date', TW: '課程開始日期' },

      letsGetStarted: { US: 'Let’s get started', TW: '讓我們開始吧' },
      setTheStartDateForEachOfYourClasses: {
        US: 'Set the start date for each of your classes.',
        TW: '設定每門課程的開始日期',
      },

      hello: { US: 'Hello', TW: '您好' },

      upgradeClass: { US: 'Upgrade Class', TW: '升級教室' },

      doneDate: { US: 'Done Date', TW: '完成日期' },

      todo: { US: 'To do', TW: '未完成' },
      overdue: { US: 'Overdue', TW: '未完成遲交' },

      MON: { US: 'MON', TW: '星期一' },
      TUE: { US: 'TUE', TW: '星期二' },
      WED: { US: 'WED', TW: '星期三' },
      THU: { US: 'THU', TW: '星期四' },
      FRI: { US: 'FRI', TW: '星期五' },
      SAT: { US: 'SAT', TW: '星期六' },
      SUN: { US: 'SUN', TW: '星期天' },

      requiredForLeadData: { US: 'Required for lead data', TW: '需要潛在客戶數據' },

      doYouWantToRemove: { US: 'Do you want to remove', TW: '你確定要移除' },

      viewHomeworkRecord: { US: 'View Homework Record', TW: '查看作業記錄' },

      dueDate: { US: 'Due date', TW: '到期日' },
      hour: { US: 'hour', TW: '時' },
      min: { US: 'min', TW: '分' },
      timeLimitExceeded: { US: 'Time limit exceeded', TW: '已超過時間限制' },

      grade: { US: 'Grade', TW: '成績' },
      currentSchool: { US: 'Current School', TW: '當前學校' },
      currentGrade: { US: 'Current Grade', TW: '當前年級' },
      schoolStage: { US: 'School Stage', TW: '教育階段' },

      selectClass: { US: 'Select class', TW: '選擇班級' },
      selectStudents: { US: 'Select students', TW: '選擇學生' },

      assignHomework: { US: 'Assign Homework', TW: '指派作業' },
      assignTo: { US: 'Assign to', TW: '指派給' },
      assignedTo: { US: 'Assigned to', TW: '指派給' },
      assignedDate: { US: 'Assigned Date', TW: '指派日' },
      assignMore: { US: 'Assign more', TW: '分配更多' },
      changeStatus: { US: 'Change Status', TW: '更改狀態' },
      changeCustomerStatus: { US: 'Change Customer Status', TW: '更改客戶狀態' },

      makeUpHomework: { US: 'Make Up Homework', TW: '補作業' },

      addNewTest: { US: 'Add New Test', TW: '新增測驗' },
      testScores: { US: 'Test Scores', TW: '測驗分數' },
      testName: { US: 'Test Name', TW: '測驗名字' },

      admin: { US: 'Admin', TW: '行政人員' },
      salesRep: { US: 'Sales Rep', TW: '業務人員' },
      educationalConsultant: { US: 'Educational Consultant', TW: '教務人員' },
      customerServiceRep: { US: 'Customer Service Rep', TW: '客服人員' },
      principal: { US: 'Principal', TW: '校長' },
      principals: { US: 'Principals', TW: '校長' },

      fullName: { US: 'Full Name', TW: '全名' },

      adminTeacher: { US: 'Admin/Teacher', TW: '管理者/老師' },
      exit: { US: 'Exit', TW: '離開' },
      folder: { US: 'Folder', TW: '資料夾' },
      newText: { US: 'New Text', TW: '新增文字' },
      deleteThisText: { US: 'Delete this text.', TW: '刪除此文字' },

      noOutline: { US: 'No Outline', TW: '無輪廓' },

      number: { US: 'number', TW: '數字' },
      numbers: { US: 'Numbers', TW: '數字' },

      stroke: { US: 'Stroke', TW: '筆畫' },
      alignLeft: { US: 'Align left', TW: '左對齊' },
      alignRight: { US: 'Align right', TW: '右對齊' },
      alignHorizontalCenter: { US: 'Align horizontal center', TW: '水平置中對齊' },

      peopleInCharge: { US: 'People in Charge', TW: '負責人' },
      principalInfo: { US: 'Principal Info', TW: '校長資訊' },
      schoolInfo: { US: 'School Info', TW: '學校資訊' },
      stepInfo: { US: 'Step Info', TW: '步驟資訊' },
      contract: { US: 'Contract', TW: '合約' },
      review: { US: 'Review', TW: '審查' },
      Info: { US: 'Info', TW: '資訊' },

      addSchool: { US: 'Add School', TW: '新增學校' },

      file: { US: 'File', TW: '檔案' },
      addFile: { US: 'Add File', TW: '新增檔案' },

      video: { US: 'Video', TW: '影片' },
      videos: { US: 'Videos', TW: '影片' },
      videoName: { US: 'Video name', TW: '影片名稱' },
      videoLink: { US: 'Video Link', TW: '影片連結' },
      videoLinkName: { US: 'Video Link Name', TW: '影片連結名稱' },
      uploadVideos: { US: 'Upload videos', TW: '上傳影片' },

      editName: { US: 'Edit name', TW: '編輯名稱' },
      editLinkName: { US: 'Edit link name', TW: '編輯連結名稱' },

      editVideoLink: { US: 'Edit video link', TW: '編輯影片連結' },

      code: { US: 'Code', TW: '授權碼' },
      addCode: { US: 'Add Code', TW: '新增授權碼' },
      editCode: { US: 'Edit Code', TW: '編輯授權碼' },
      deleteCode: { US: 'Delete Code', TW: '刪除授權碼' },

      accountPrefix: { US: 'Account Prefix', TW: '帳號前綴' },

      doYouWantToDeleteCode: {
        US: 'Do you want to delete code?',
        TW: '您要刪除授權碼嗎?',
      },
      type: { US: 'Type', TW: '類型' },

      princialFullName: { US: 'Princial Full Name', TW: '校長全名' },
      principalPhone: { US: 'Principal Phone', TW: '校長電話' },
      principalAccount: { US: 'Principal Account', TW: '校長帳號' },

      selectExistingPrincipalAccount: {
        US: 'Select existing principal account',
        TW: '選擇現有校長帳號',
      },

      usernameMadeWithPrefixCountryCodeSerialNumberTheSerialNumberIsAutomaticallyGenerated:
        {
          US: 'The username is made with PREFIX + COUNTRY CODE + SERIAL NUMBER. The serial number is automatically generated.',
          TW: '使用者名稱由前綴 + 國家代碼 + 序號組成。 序號是自動產生的',
        },
      createNewPrincipalAccount: {
        US: 'Create new principal account',
        TW: '建立新校長帳戶',
      },

      wechat: { US: 'Wechat', TW: '微信' },

      birthday: { US: 'Birthday(yyyy/mm/dd)', TW: '生日(yyyy/mm/dd)' },

      link: { US: 'Link', TW: '連結' },
      addLink: { US: 'Add Link', TW: '新增連結' },
      copyLink: { US: 'Copy Link', TW: '複製連結' },
      linkName: { US: 'Link Name', TW: '連結名稱' },
      editVideoLinkName: { US: 'Edit video link name', TW: '編輯影片連結名稱' },
      pleaseEnterAVideoLink: { US: 'Please enter a video link.', TW: '請輸入影片連結' },
      pleaseEnterAValidUrl: { US: 'Please enter a valid url.', TW: '請輸入有效網址' },
      pleaseEnterAVideoLinkName: {
        US: 'Please enter a video link name.',
        TW: '請輸入影片網址名稱',
      },

      pleaseEnterAValidNumber: {
        US: 'Please enter a valid number.',
        TW: '請輸入有效號碼',
      },
      pleaseEnterABiggerNumber: {
        US: 'Please enter a bigger number',
        TW: '請輸入更大的數字',
      },
      pleaseEnterASmallerNumber: {
        US: 'Please enter a smaller number',
        TW: '請輸入一個較小的數字',
      },

      leaveItBlankForUnrestrictedAccess: {
        US: 'Leave it blank for unrestricted access.',
        TW: '將其留空以不受限制的訪問',
      },

      pleaseEnterAValidVideoUrl: {
        US: 'Please enter a valid video url.',
        TW: '請輸入有效影片網址',
      },
      pleaseEnterAVideoUrl: { US: 'Please enter a video url.', TW: '請輸入影片網址' },
      pleaseEnterALinkName: { US: 'Please enter a link name.', TW: '請輸入連結名字' },
      pleaseEnterALink: { US: 'Please enter a link.', TW: '請輸入連結' },
      pleaseEnterANumber: { US: 'Please enter a number', TW: '請輸入數字' },
      pleaseEnterAFileName: { US: 'Please enter a file name.', TW: '請輸入檔案名字' },
      pleaseEnterAValidLink: { US: 'Please enter a valid link.', TW: '請輸入有效連結' },
      pleaseEnterAClassName: { US: 'Please enter a Class Name.', TW: '請輸入教室名字' },
      pleaseEnterAUrl: { US: 'Please enter a url.', TW: '請輸入網址' },
      pleaseEnterA: { US: 'Please enter a', TW: '請輸入' },
      pleaseEnter: { US: 'Please enter', TW: '請輸入' },
      pleaseEnterAVideoName: { US: 'Please enter a video name.', TW: '請輸入影片名稱' },
      pleaseFollowTheLinkBelowAndCreateAnAccountWithTheInvitationCode: {
        US: 'Please follow the link below and create an account with the invitation code.',
        TW: '請點擊下面的連結並使用邀請碼建立帳戶.',
      },
      pleaseEnterUsernameAndPassword: {
        US: 'Please enter your username and password.',
        TW: '請輸入您的帳號密碼',
      },

      removeStudentFromClass: {
        US: 'Remove Student From Class',
        TW: '將學生從班級中刪除',
      },

      yourInvitationCodeWillAutomaticallyBeFilledInOnTheFormForYouWhenYouClickOnTheLink: {
        US: 'Your invitation code will automatically be filled in on the form for you when you click on the link.',
        TW: '您的邀請碼將自動填寫在表格中當您點擊連結時.',
      },

      thisItemWillBeMovedToTheTrash: {
        US: 'This item will be moved to the trash.',
        TW: '此項目將被移至垃圾桶.',
      },

      youCanRestoreItFromTheTrashIfYouChangeYourMind: {
        US: 'You can restore it from the trash if you change your mind.',
        TW: '您可以從垃圾桶復原.',
      },

      upgradeClassLevel: { US: 'Upgrade Class Level', TW: '升級班級等級' },

      lostCustomers: { US: 'Lost Customers', TW: '流失的客戶' },
      noEventsAttended: { US: 'No Events Attended', TW: '沒參加活動' },
      notCalled: { US: 'Not Called', TW: '沒被聯絡' },

      scrollToTop: { US: 'Scroll to Top', TW: '捲動至頂部' },

      useVideoURL: { US: 'Use video URL.', TW: '使用影片網址' },
      useVideoFile: { US: 'Use video file.', TW: '使用影片檔案' },
      shareCode: { US: 'Share Code', TW: '分享邀請碼' },

      fileName: { US: 'File name', TW: '檔案名字' },

      deleteLevel: { US: 'Delete Level', TW: '刪除 Level' },
      deleteLevels: { US: 'Delete Levels', TW: '刪除 Levels' },
      deleteLesson: { US: 'Delete Lesson', TW: '刪除 Lesson' },
      deleteLessons: { US: 'Delete Lessons', TW: '刪除 Lessons' },
      deleteBook: { US: 'Delete Book', TW: '刪除 Book' },
      deleteBooks: { US: 'Delete Books', TW: '刪除 Books' },
      deletePart: { US: 'Delete Part', TW: '刪除 Part' },
      deleteParts: { US: 'Delete Parts', TW: '刪除 Parts' },
      deleteStyle: { US: 'Delete Style', TW: '刪除 Style' },
      deleteStyles: { US: 'Delete Styles', TW: '刪除 Styles' },
      deleteResource: { US: 'Delete Resource', TW: '刪除 Resource' },

      deleteThisAudio: { US: 'Delete this audio.', TW: '刪除此音檔' },
      deleteThisVideo: { US: 'Delete this video.', TW: '刪除此影片' },
      deleteThisElement: { US: 'Delete this element.', TW: '刪除此元件' },
      deleteThisImage: { US: 'Delete this image.', TW: '刪除此圖片' },
      deleteThisOption: { US: 'Delete this option.', TW: '刪除此選項' },
      deleteThisComponent: { US: 'Delete this component', TW: '刪除此組件' },
      lockAspectRatio: { US: 'Lock aspect ratio', TW: '鎖定縱橫比' },
      unlockAspectRatio: { US: 'Unlock aspect ratio', TW: '解鎖縱橫比' },
      editVideoAnswer: { US: 'Edit video answer', TW: '編輯影片答案' },

      addANewWord: { US: 'Add a new word.', TW: '新增單字' },
      deleteThisWord: { US: 'Delete this word.', TW: '刪除此單字' },

      addANewOption: { US: 'Add a new option.', TW: '新增選項' },
      addANewQuestion: { US: 'Add a new question.', TW: '新增題目' },

      pleaseEnterAnAnswer: { US: 'Please enter an answer', TW: '請輸入答案' },
      nextQuestion: { US: 'next question', TW: '下一題' },
      play: { US: 'Play', TW: '播放' },
      autoPlay: { US: 'AutoPlay', TW: '自動播放' },

      fullScreen: { US: 'Full Screen', TW: '全螢幕' },

      to: { US: 'To', TW: '到' },
      from: { US: 'From', TW: '從' },
      moveToTrash: { US: 'Move to Trash', TW: '移至垃圾桶' },
      move: { US: 'Move', TW: '移動' },
      moveTo: { US: 'Move To:', TW: '移動至:' },
      moveFile: { US: ' Move File', TW: '移動檔案' },
      moveMedia: { US: 'Move Media', TW: '移動媒體' },

      moveToFolder: { US: 'Move to folder', TW: '移動至資料夾' },
      toFolder: { US: 'To Folder', TW: '到資料夾' },

      selectATest: { US: 'Select a test', TW: '選擇測驗' },
      deleteTest: { US: 'Delete Test', TW: '刪除測驗' },
      selectYourTest: { US: 'Select your test', TW: '選擇你的測驗' },

      enterUsername: { US: 'Enter Username', TW: '輸入使用者名稱' },
      enterPassword: { US: 'Enter Password', TW: '輸入密碼' },
      goToMusen: { US: 'Go to Musen', TW: '前往木森' },
      invalidCode: { US: 'Invalid Code', TW: '此為無效碼' },
      theUserameHasBeenTaken: {
        US: 'The username has been taken.',
        TW: '使用者名稱已被佔用',
      },

      layerTopDown: { US: 'Layer: Top-Down', TW: '層級: 上-下' },

      dataLoadFailed: { US: 'Data load failed.', TW: '資料加載失敗' },

      filesAndLinks: { US: 'Files and links', TW: '檔案跟連結' },

      search: { US: 'Search', TW: '搜尋' },

      // searchSchool: { US: 'Search school', TW: '搜尋學校' },
      ['Search School']: { US: 'Search school', TW: '搜尋學校' },
      ['Search Classroom']: { US: 'Search Classroom', TW: '搜尋教室' },
      searchResources: { US: 'Search resources', TW: '搜尋資源' },
      ['Search Media']: { US: 'Search Media', TW: '搜尋媒體' },
      ['Search Folder']: { US: 'Search folder', TW: '搜尋資料夾' },
      searchStudent: { US: 'Search student', TW: '搜尋學生' },

      schoolName: { US: 'School name', TW: '學校名字' },
      studentLimit: { US: 'Student Limit', TW: '學生限制' },

      createAStudent: { US: 'Create a student', TW: '建立學生' },
      editStudent: { US: 'Edit Student', TW: '編輯學生' },

      sorrySKUisTaken: { US: 'Sorry, SKU is taken.', TW: '抱歉,SKU碼已被佔用' },
      signUp: { US: 'Sign Up', TW: '註冊' },
      logIn: { US: 'Log In', TW: '登入' },

      teachAccessControl: { US: 'Teach Access Control', TW: '教學權限控制' },
      resourceAccessControl: { US: 'Resource Access Control', TW: '資源權限控制' },
      learnAccessControl: { US: 'Learn Access Control', TW: '學習權限控制' },

      classroomAccessControl: { US: 'Classroom Access Control', TW: '教室權限控制' },
      accessControl: { US: 'Access Control', TW: '權限控制' },
      allSchoolCanAccess: { US: 'All schools can access', TW: '所有學校都可以訪問' },
      selectedSchools: { US: 'Selected school', TW: '選定學校' },

      selectSchools: { US: 'Select schools', TW: '選擇學校' },

      willBeDeletedThisActionCannotBeUndone: {
        US: 'will be deleted. This action cannot be undone!',
        TW: '將要刪除，此操作後無法復原!',
      },
      ifThe: { US: 'If the', TW: '假如' },
      isUsedOnAStyle: { US: 'is used on a style，', TW: '用於樣式，' },
      willStillBeAvailableForThatStyleButItWillBeDeletedFromHere: {
        US: 'will still be available for that style，but it will be deleted from here.',
        TW: '該樣式仍然可用，但將從此處刪除',
      },
      thisActionCannotBeUndone: {
        US: 'This action cannot be undone!',
        TW: '此操作後無法復原!',
      },

      checkStudentGrades: { US: 'Check Student Grades', TW: '檢查學生成績' },
      studentsHomeworkNotDone: { US: 'Students homework not done', TW: '學生作業沒做完' },

      studentHomeWorkLimitTimeWarning: {
        US: `The time student is given to complete the homework. For example, once they start, they have only 30 minutes to finish.`,
        TW: '給予學生完成作業的時間。 例如，一旦開始，他們只有 30 分鐘的時間完成.',
      },

      noTimeLimit: { US: '0:00 is no time limit.', TW: '0:00 沒有時間限制.' },
      timeIsUp: { US: 'Time is up!', TW: '時間到!' },
      editDueDate: { US: 'Edit Due Date', TW: '編輯截止日期' },

      cannotBeEarlierThanStartDate: {
        US: 'Cannot be earlier than start date.',
        TW: '不能早於開始日期.',
      },

      timeIsSetToTheDateAtAlmost24: {
        US: 'Time is set to the date at 23:59:59',
        TW: '時間設定為日期 23:59:59',
      },

      automaticallyCreateHowManyTeacherCodes: {
        US: ' Automatically create how many teacher codes',
        TW: '自動建立多少個老師授權碼',
      },

      timeLimit: { US: 'Time limit', TW: '時限' },

      typeYourTeachingNote: {
        US: 'Type your teaching note....',
        TW: '輸入你的教學筆記...',
      },

      checkHereIfTheSchoolSKUisNotUnique: {
        US: "Check here if the school's SKU is not unique (a branch school)",
        TW: '檢查學校的Sku是否重複(分校)',
      },

      newAccountPreview: { US: 'New Account Preview', TW: '預覽新帳戶' },

      addVideos: { US: 'Add Videos', TW: '新增影片' },
      addAudio: { US: 'Add Audio', TW: '新增音檔' },
      theseAudioFilesWillBeUploaded: {
        US: 'These audio files will be uploaded:',
        TW: '這些音檔將被上傳:',
      },
      theseFilesWillBeUploaded: {
        US: 'These files will be uploaded:',
        TW: '這些檔案將被上傳:',
      },
      theseVideoFilesWillBeUploaded: {
        US: 'These video files will be uploaded:',
        TW: '這些影片檔案將被上傳:',
      },

      uploadAudios: { US: 'Upload audios', TW: '上傳音檔' },
      uploadFiles: { US: 'Upload files', TW: '上傳檔案' },
      clickTheIconOrDropAudioFilesInTheBoxToUpload: {
        US: 'Click the icon or drop audio files in the box to upload.',
        TW: '點擊圖示或將音檔拖放到框中即可上傳.',
      },

      clickTheIconOrDropVideoFilesInTheBoxToUpload: {
        US: 'Click the icon or drop Video files in the box to upload.',
        TW: '點擊圖示或將影片拖放到框中即可上傳.',
      },

      clickTheIconOrDropFilesInTheBoxToUpload: {
        US: 'Click the icon or drop files in the box to upload.',
        TW: '點擊圖示或將檔案拖放到框中即可上傳.',
      },

      copied: { US: 'Copied!', TW: '已複製' },
      pleaseWait: { US: 'Please wait...', TW: '請稍等...' },
      processing: { US: 'Processing', TW: '處理中' },
      upload: { US: 'Upload', TW: '上傳' },

      copyErrorText: { US: 'Copy error text', TW: '複製錯誤文字' },
      copyFailedTryAgain: { US: 'Copy failed, Try again.', TW: '複製失敗，請重試' },

      addedBy: { US: 'Added By', TW: '添加者' },
      dateAdded: { US: 'Date Added', TW: '新增日期' },
      leadSource: { US: 'Lead Source', TW: '潛在客戶來源' },
      leadBy: { US: 'Lead By', TW: '開發人員' },

      addSkill: { US: 'Add Skill', TW: '新增技能' },
      addSubSkillTo: { US: 'Add SubSkill to', TW: '新增子技能至' },
      editSkill: { US: 'Edit Skill', TW: '編輯技能' },
      editSubSkill: { US: 'Edit SubSkill', TW: '編輯子技能' },

      subSkill: { US: 'SubSkill', TW: '子技能' },
      skill: { US: 'Skill', TW: '技能' },
      noWeakSkill: { US: 'No weak skill', TW: '沒有弱技能' },
      weakSkill: { US: 'Weak Skill', TW: '較弱技能' },
      noPartData: { US: 'No Part Data', TW: '沒有 Part 資料' },

      loading: { US: 'Loading...', TW: '加載中...' },
      loadMore: { US: 'Load More', TW: '載入更多' },

      submitScores: { US: 'Submit Scores', TW: '提交分數' },

      folderName: { US: 'Folder name', TW: '資料夾名字' },
      editFolderName: { US: 'Edit folder name', TW: '編輯資料夾名字' },
      enterNewFolderName: { US: 'Enter new folder name', TW: '輸入新資料夾名字' },

      psMakeSureToSetYourAccesstToken: {
        US: 'PS: Make sure to set your access token!',
        TW: 'PS: 請務必設定您的訪問令牌!',
      },

      pleaseSelectAProductFirst: {
        US: 'Please select a product first.',
        TW: '請先選擇產品',
      },
      pleaseEnterAStyleName: { US: 'Please enter a style name', TW: '請輸入style名字' },
      pleaseEnterAText: { US: 'Please enter a text.', TW: '請輸入文字' },
      pleaseEnterAAudioName: { US: 'Please enter a audio name.', TW: '請輸入音檔名字' },

      operationSuccessfully: { US: 'Operation successfully.', TW: '操作成功' },
      operationFailed: { US: 'Operation failed.', TW: '操作失敗' },

      linkHasBeenCopied: { US: 'Link has been copied.', TW: '連結已複製' },
      linkCopyFailed: { US: 'Link copy failed.', TW: '連結複製失敗' },

      successfullyAddTheTest: { US: 'Successfully add the Test', TW: '新增測驗成功' },
      successfullyDeleteTheTest: {
        US: 'Successfully delete the Test',
        TW: '刪除測驗成功',
      },
      successfullySubmitTheScore: {
        US: 'Successfully submit the Score',
        TW: '提交分數成功',
      },

      createSchool: { US: 'Create School', TW: '建立學校' },

      restrictAccessToTheProductsInThisContract: {
        US: 'Restrict access to the products in this contract.',
        TW: '限制存取本合約中的產品（教材）',
      },

      areYouSureYouWantToLeave: {
        US: 'Are you sure you want to leave?',
        TW: '您確定要離開嗎?',
      },
      yourWorkingWillBeSaved: {
        US: 'Your working will be saved!',
        TW: '你的進度將會被保存!',
      },

      yourWorkingWillNotBeSaved: {
        US: 'Your working will not be saved!',
        TW: '你的進度將不會被保存!',
      },

      yourBrowserDoesNotSupportTheAudioElement: {
        US: 'Your browser does not support the audio element.',
        TW: '您的瀏覽器不支援音訊元素.',
      },

      areYouSureYouWantToExitThePage: {
        US: 'Are you sure you want to exit the page?',
        TW: '您確定要退出該頁面嗎',
      },

      checking: { US: 'Checking', TW: '檢查中' },

      doYouWantToUpdate: { US: 'Do you want to update?', TW: '你想更新嗎?' },
      doYouWantToRestoreTheSelectedLevel: {
        US: 'Do you want to restore the selected level?',
        TW: '您想復原所選level嗎?',
      },

      doYouWantToRestoreTheSelectedLesson: {
        US: 'Do you want to restore the selected lesson?',
        TW: '您想復原所選lesson嗎?',
      },
      doYouWantToRestoreTheSelectedBook: {
        US: 'Do you want to restore the selected book?',
        TW: '您想復原所選book嗎?',
      },

      doYouWantToRestoreTheSelectedPart: {
        US: 'Do you want to restore the selected part?',
        TW: '您想復原所選part嗎?',
      },

      doYouWantToRestoreTheSelectedStyle: {
        US: 'Do you want to restore the selected style?',
        TW: '您想復原所選style嗎?',
      },

      doYouWantToRestoreTheSelectedResource: {
        US: 'Do you want to restore the selected Resource?',
        TW: '您想復原所選resource嗎?',
      },

      theInformationYouEnteredMayNotBeSaved: {
        US: 'The information you entered may not be saved',
        TW: '您輸入的資料可能不會被保存',
      },

      thisPhoneNumberHasAlreadyBeenEntered: {
        US: 'This phone number has already been entered.',
        TW: '此電話號碼已被輸入',
      },

      thisPhoneNumberIsInvalid: {
        US: 'This phone number is invalid',
        TW: '該電話號碼無效',
      },

      pleaseEnterPhoneNumber: {
        US: 'Please enter phone number',
        TW: '請輸入電話號碼*(必填)',
      },

      pleaseChooseASource: { US: 'Please choose a Source.', TW: '請選擇來源' },
      PleaseSelectAtLeastOneOptions: {
        US: 'Please select at least 1 options.',
        TW: '請至少選擇 1 個選項.',
      },
      pleaseEnterAtLeastOneAnswer: {
        US: 'Please enter at least one answer!',
        TW: '請至少輸入一個答案!',
      },

      pleaseUse_ToMakeTextQuestion: {
        US: 'Please use _ to make text Question',
        TW: "請用'_'來標記問題",
      },
      theAnswerDoesNotMatchTheWordCountOfTheQuestion: {
        US: 'The answer does not match the word count of the question',
        TW: '答案跟問題的字數不相符',
      },

      pleaseSelectAnOption: { US: 'Please select an option.', TW: '請選擇一個選項' },

      pleaseSelectAClassroom: { US: 'Please select a classroom', TW: '請選擇教室' },

      pleaseSelectADate: { US: 'Please select a date.', TW: '請選擇日期' },
      pleaseSelectA: { US: 'Please select a ', TW: '請選擇' },

      ['The end time cannot be earlier than the start time.']: {
        US: 'The end time cannot be earlier than the start time.',
        TW: '結束時間不能早於開始時間',
      },

      toRemoveTheStudentFromTheClassTypeRemove: {
        US: 'To remove the student from the class, type "remove".',
        TW: '若要將學生從班級中刪除，請輸入「刪除」',
      },

      transferToAnotherClass: { US: 'Transfer to Another Class', TW: '轉到另一個班級' },

      typeOfTransfer: { US: 'Type Of Transfer', TW: '轉班類型' },

      leaveItBlankToResetThePasswordTo: {
        US: 'Leave it blank to reset the password to',
        TW: '將其留空以將密碼重設為',
      },

      coursesWillBeEmptiedAreYouSureYouWantToContinue: {
        US: 'courses will be emptied, are you sure you want to continue?',
        TW: '課程將被清空，您確定要繼續嗎?',
      },

      distinctCourse: { US: 'Distinct Course(s)', TW: '不同的課程' },
      identicalCourse: { US: 'Identical Course(s)', TW: '相同的課程' },

      coursesBetweenClassroomsMustBeTotallyTheSame: {
        US: 'Courses between classrooms must be totally the same.',
        TW: '教室之間的課程必須完全相同',
      },

      coursesBetweenClassroomsMustBeTotallyDifferent: {
        US: 'Courses between classrooms must be totally different.',
        TW: '教室之間的課程必須是完全不同的',
      },

      whenDuplicatingADailyTheNumberOfTeachersShouldMatchTheOriginalOne: {
        US: 'When duplicating a Daily, the number of teachers should match the original one.',
        TW: '',
      },

      downloadExcelTemplate: { US: 'Download Excel template', TW: '下載Excel模板' },
      dropExcelFileHere: {
        US: 'Drop Excel file here ...',
        TW: '將 Excel 檔案拖曳到此處',
      },

      leadsToCustomers: { US: 'Leads → Customers', TW: '潛在客戶 → 客戶' },

      cannotBeEarlierThanToday: {
        US: 'Cannot be earlier than today.',
        TW: '不能早於今天',
      },
      pleaseSetANewDueDay: { US: 'Please set a new due day', TW: '請設定新的截止日期' },

      everySchedulewillBePushedOneDayForwardBasedOnTheScheduleYouSelected: {
        US: ' Every schedule will be pushed one day forward based on the schedule you selected.',
        TW: ' 每個日程都會根據您選擇的日程延後一周',
      }, //TODO: 翻譯?

      ifYouChangeTheCourseStartDateOrClassesPerWeekWeWillRearrangeTheSchedulesForYourClass:
        {
          US: 'If you change the Course start date or Classes per week, we will rearrange the schedules for your class.',
          TW: '如果您更改課程開始日期或每週課程，我們將重新安排您的課程表.',
        },

      hasReachedBottom: { US: 'has reached bottom', TW: '已到底部' },

      sorryThisNameIsTaken: {
        US: 'Sorry, this name is taken',
        TW: '抱歉, 此名稱已被使用',
      },
      youCannotDeleteThis: {
        US: 'You cannot delete this ',
        TW: '您無法刪除 ',
      },
      becauseItIsNotEmpty: {
        US: 'because it is not empty. You must delete everything inside first before it can be deleted.',
        TW: '因為它不是空的. 您必須先刪除它裡面的所有資料,才能刪除它.',
      },
      theFollowingProblemsMayHaveOccurred: {
        US: 'the following problems may have occurred',
        TW: '可能出現以下問題',
      },
      yourNetworkIsDisconnected: {
        US: 'Your network is disconnected',
        TW: '網路連接已斷開',
      },
      yourNetworkHasBeenDisconnected: {
        US: 'Your network has been disconnected',
        TW: '網路連接已斷開',
      },
      theServerIsNotResponding: {
        US: 'The server is not responding. Please try again later',
        TW: '伺服器無回應. 請稍後再試',
      },
      thisAccountHasBeenLoggedOut: {
        US: 'This account has been logged out, please refresh the page and log in again',
        TW: '這個帳號已被登出, 請重新整理再登入',
      },
      couldNotBeFound: {
        US: 'could not be found',
        TW: '找不到',
      },
    }),
    [],
  );

  const languageScripts = useMakeScript(languageDictionary);

  return languageScripts;
};
