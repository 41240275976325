import { useEffect, useRef, useState } from 'react';

import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import CustomButton from './CustomButton';
import { useScripts } from '../layout/utils/LanguageHelper';
import AddCircleIcon from '../svg/AddCircleIcon';
import AddRoundedIcon from '../svg/AddRoundedIcon';
import EditIcon from '../svg/EditIcon';
import ReportProblemOutlinedIcon from '../svg/ReportProblemOutlinedIcon';

type CustomAddOrEditDialogProps = {
  children: any;
  isAdd: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  icon?: JSX.Element;
  titleIcon?: JSX.Element;
  open: boolean;
  buttonSize?: 'medium' | 'large' | 'small';
  buttonColor?: string;
  hoverButtonColor?: string;
  tooltipTitle?: string;
  dialogTitle: string | JSX.Element;
  dialogTitleAlign?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  getCustomButton?: (
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  ) => JSX.Element;
  ContentTitle?: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: () => void;
  onSubmit?: () => void;
  fullWidth?: boolean;
  spacing?: number;
  justifyContent?: string;
  alignItems?: string;
  showActionButton?: boolean;
  showHoverBackgroundColor?: boolean;
  noIcon?: boolean;
  hoverBackgroundColor?: string;
  addIcon?: boolean;
  danger?: boolean;
};

const CustomAddOrEditDialog = ({
  children,
  isAdd,
  maxWidth = 'md',
  icon,
  titleIcon,
  open,
  buttonSize = 'medium',
  buttonColor = '#00A591',
  hoverButtonColor = '#FFF',
  tooltipTitle,
  dialogTitle,
  dialogTitleAlign = 'left',
  getCustomButton,
  ContentTitle,
  onClick,
  onClose,
  onSubmit,
  fullWidth = true,
  spacing = 2,
  justifyContent = 'start',
  alignItems = 'start',
  showActionButton = true,
  showHoverBackgroundColor = false,
  noIcon = false,
  hoverBackgroundColor = '#08A590',
  addIcon = false,
  danger,
}: CustomAddOrEditDialogProps): JSX.Element => {
  const isCancelButtonFocusedRef = useRef(false);

  const scripts = useScripts();

  const webMode = localStorage.getItem('theme');

  const customerButtonCSSProps: SxProps<Theme> = {
    width: '92px',
    '&:hover': {
      borderColor: danger ? '#FF6663' : '#00A591',
      backgroundColor: danger ? '#FF6663' : '#00A591',
      color: '#fff',
    },
  };

  useEffect(() => {
    if (open) {
      isCancelButtonFocusedRef.current = false;
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  }, [open]);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {getCustomButton
        ? getCustomButton(onClick)
        : (isAdd &&
            (!noIcon ? (
              <Tooltip title={tooltipTitle}>
                <IconButton
                  onClick={onClick}
                  size={buttonSize}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  sx={{
                    width: buttonSize === 'large' ? '60px' : 'auto',
                    height: buttonSize === 'large' ? '60px' : 'auto',
                    ':hover': {
                      backgroundColor: showHoverBackgroundColor
                        ? hoverBackgroundColor
                        : 'none',
                    },
                  }}
                >
                  {addIcon ? (
                    <AddRoundedIcon
                      width={24}
                      height={24}
                      color={isHovered ? hoverButtonColor : '#00A591'}
                    />
                  ) : (
                    <AddCircleIcon
                      width={buttonSize === 'large' ? 32 : 24}
                      height={buttonSize === 'large' ? 32 : 24}
                    />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            ))) || (
            <Tooltip title={tooltipTitle}>
              <IconButton
                onClick={onClick}
                size={buttonSize}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                  ':hover': {
                    backgroundColor: showHoverBackgroundColor
                      ? hoverBackgroundColor
                      : 'none',
                  },
                }}
              >
                {icon ? (
                  icon
                ) : (
                  <EditIcon color={isHovered ? hoverButtonColor : buttonColor} />
                )}
              </IconButton>
            </Tooltip>
          )}
      {open && (
        <Dialog
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          open={open}
          onClose={onClose}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (isCancelButtonFocusedRef.current) {
              return;
            }

            const code = e?.code?.toLowerCase() ?? '';
            if (
              showActionButton &&
              (code === 'enter' || code === 'numpadenter') &&
              !e.nativeEvent.isComposing
            ) {
              onSubmit && onSubmit();
            }
          }}
          PaperProps={{
            sx: {
              maxHeight: 'calc(100% - 192px)',
              borderRadius: '15px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5em',
            }}
          >
            <Box
              sx={{
                width: '50%',
                height: '0.3em',
                bgcolor: danger ? '#FF6663' : '#00A591',
              }}
            />
          </Box>
          {danger && (
            <>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                mt={1}
              >
                <ReportProblemOutlinedIcon />
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: '#FF6663', fontFamily: 'Poppins' }}
                >
                  {scripts.warning}
                </Typography>
              </Stack>
              <DialogTitle component={Box}>{dialogTitle}</DialogTitle>
            </>
          )}
          {!danger && (
            <DialogTitle
              component={Box}
              align={dialogTitleAlign}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {isAdd ? (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  mr="1em"
                >
                  <AddRoundedIcon color="#00A591" width={35} height={35} />
                </Stack>
              ) : titleIcon ? (
                titleIcon
              ) : (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  mr="1em"
                >
                  <EditIcon width={35} height={35} color="#00A591" />
                </Stack>
              )}

              {dialogTitle}
            </DialogTitle>
          )}
          <DialogContent
            sx={{
              position: 'relative',
              paddingTop: '8px !important',
              paddingBottom: '0 !important',
            }}
          >
            <Stack spacing={4}>
              {ContentTitle ? <>{ContentTitle}</> : null}
              <Grid
                container
                spacing={spacing}
                justifyContent={justifyContent}
                alignItems={alignItems}
                sx={{
                  '--Grid-columns': 12,
                  '--Grid-columnsLevel2': 12,
                }}
              >
                {children}
              </Grid>
              {(showActionButton && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={12}
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    paddingTop: '10px',
                    paddingBottom: '20px',
                    backgroundColor: webMode === 'Light' ? '#fff' : 'gray',
                    zIndex: 2,
                  }}
                >
                  <CustomButton
                    variant="outlined"
                    sx={{ ...customerButtonCSSProps }}
                    onClick={onClose}
                    onBlur={() => (isCancelButtonFocusedRef.current = false)}
                    onFocus={() => (isCancelButtonFocusedRef.current = true)}
                    danger={danger}
                  >
                    {scripts.cancel}
                  </CustomButton>
                  <CustomButton
                    variant="outlined"
                    sx={{ ...customerButtonCSSProps }}
                    onClick={onSubmit}
                    danger={danger}
                  >
                    {scripts.ok}
                  </CustomButton>
                </Stack>
              )) || <Box sx={{ height: '2px' }}></Box>}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CustomAddOrEditDialog;
