import { useEffect, useRef, useId, forwardRef, useState, useLayoutEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { Box, ContainerProps } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import {
  OperationResultProvider,
  useOperationResultContext,
} from '../globalContexts/OperationResultContext';
import { useAuth } from '../globalHooks/useAuth';
import NoResultFoundSvg from '../layout/utils/NoResultFoundSvg';

type CustomContainerProps = Omit<ContainerProps, 'ref'> & {
  hasData: boolean;
  loading: boolean; // not fetch more loading.
  error?: ApolloError | null | undefined;
  setTitleTop?: (titleTop: number) => void;
};

const CustomContainerShow = forwardRef<HTMLDivElement, CustomContainerProps>(
  ({ hasData, loading, error, setTitleTop, children, ...props }, ref) => {
    const { operationLoading, operationLoaded, commonErrorMessages } =
      useOperationResultContext();

    const containerId = useId();

    const rootRef = useRef<HTMLDivElement | null>(null);
    const resizeTimerRef = useRef<NodeJS.Timeout | null>(null);

    const { managerRole } = useAuth();
    const managerRoleRef = useRef(managerRole);
    managerRoleRef.current = managerRole;

    useEffect(() => {
      if (error) {
        commonErrorMessages(error);
        return;
      }
      if (loading) {
        operationLoading();
        return;
      }
      if (hasData || !loading) {
        operationLoaded();
      }
    }, [hasData, error, loading]);

    useEffect(() => {
      if (!setTitleTop) {
        return;
      }

      const resize = () => {
        if (resizeTimerRef.current) {
          clearTimeout(resizeTimerRef.current);
        }

        resizeTimerRef.current = setTimeout(() => {
          if (!rootRef.current) {
            rootRef.current = document.getElementById('root') as HTMLDivElement | null;
          }
          const root = rootRef.current as HTMLDivElement;
          if (!managerRoleRef.current) {
            const menu = root.children.item(0) as HTMLDivElement | null;
            if (menu) {
              setTitleTop(menu.offsetHeight);
            }
          } else {
            setTitleTop(0);
          }
        }, 100);
      };

      resize();

      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }, [hasData]);

    const boxRef = useRef<HTMLDivElement>(null);
    const [isFinish, setIsFinish] = useState(false);
    const [hasStickyElementCopy, setHasStickyElementCopy] = useState(false);
    const isFirstLoadRef = useRef(true);
    useLayoutEffect(() => {
      const container = document.getElementById(containerId) as HTMLDivElement | null;
      if (container && !hasData && loading && !error && !isFinish) {
        let stickyElement: HTMLDivElement | null = null;
        const getStickyElement = (
          child: HTMLDivElement | null,
          stickyElement: HTMLDivElement | null,
        ) => {
          if (child && !stickyElement) {
            const cssCollection = window.getComputedStyle(child, null);
            const position = cssCollection.getPropertyValue('position');
            const width = cssCollection.getPropertyValue('width');
            if (
              position === 'sticky' &&
              Number.parseFloat(width) >= window.innerWidth * 0.7
            ) {
              return child;
            }
          }

          return null;
        };

        for (
          let i = 0, children = container.children;
          i < children.length && !stickyElement;
          i++
        ) {
          const child1 = children.item(i) as HTMLDivElement | null;
          stickyElement = getStickyElement(child1, stickyElement);
          for (let j = 0; j < (child1?.children.length ?? 0) && !stickyElement; j++) {
            const child2 = (child1?.children?.item(j) ?? null) as HTMLDivElement | null;
            stickyElement = getStickyElement(child2, stickyElement);
          }
        }

        if (stickyElement) {
          const copy = stickyElement.parentElement?.cloneNode(true) as HTMLElement | null;
          copy?.replaceChildren(stickyElement.cloneNode(true));
          if (boxRef.current && copy) {
            boxRef.current.replaceChildren(copy);
            setHasStickyElementCopy(true);
          }
        }
      } else if (!loading && !isFirstLoadRef.current) {
        setHasStickyElementCopy(false);
        setIsFinish(true);
      }

      isFirstLoadRef.current = false;
    }, [hasData, loading, error]);

    return (
      <>
        <Container
          id={containerId}
          ref={ref}
          {...{ maxWidth: 'xl', ...props, sx: { position: 'relative', ...props.sx } }}
        >
          {!isFinish && <Box ref={boxRef} />}
          {!hasStickyElementCopy && children}
        </Container>
        {!hasData && !loading && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            left={`${((window.innerWidth - 217) / 2 / window.innerWidth) * 100}%`}
            top={`${((window.innerHeight - 274) / 2 / window.innerHeight) * 100}%`}
          >
            <NoResultFoundSvg />
          </Stack>
        )}
      </>
    );
  },
);
CustomContainerShow.displayName = 'CustomContainerShow';

const CustomContainer = forwardRef<HTMLDivElement, CustomContainerProps>(
  ({ hasData, loading, error, setTitleTop, children, ...props }, ref) => {
    return (
      <OperationResultProvider>
        <CustomContainerShow
          ref={ref}
          hasData={hasData}
          loading={loading}
          error={error}
          setTitleTop={setTitleTop}
          {...props}
        >
          {children}
        </CustomContainerShow>
      </OperationResultProvider>
    );
  },
);
CustomContainer.displayName = 'CustomContainer';

export default CustomContainer;
